<template>
	<el-dialog v-dialogDrag title="新增奖扣权限" :visible.sync="visible" width="40%">
		<el-form label-width="100px" ref="form" :model="formParam" :rules="rules">
			<el-form-item label="权限名称" prop="permissionName">
				<el-input placeholder="名称长度限制30字符" maxLength="30"  v-model="formParam.permissionName"></el-input>
			</el-form-item>
			<el-form-item label="B分权限" prop="bScore">
				 <el-input-number v-model="formParam.bScore" :min="0"></el-input-number>
			</el-form-item>
			<el-form-item label="A分权限" prop="aScore">
				 <el-input-number v-model="formParam.aScore" :min="0"></el-input-number>
			</el-form-item>
			<el-form-item label="产值权限" prop="outputValue">
				 <el-input-number v-model="formParam.outputValue" :min="0"></el-input-number>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
			<el-button size="small" type="primary" @click="submit" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return{
				formParam:{},
				btnLoading:false,
				rules:{
					permissionName:[{ required: true, message: '请输入权限名称', trigger: 'blur' }],
					bScore:[{ required: true, message: 'B分权限', trigger: 'blur' }],
					// aScore:[{ required: true, message: 'A分权限', trigger: 'blur' }],
					// outputValue:[{ required: true, message: '产值权限', trigger: 'blur' }],
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			id: {
				type: Array|Number,
				default:null
			},
		},
		computed: {
			visible: {
				get() {
					if(this.id){
						this.getDetail()
					}else{
						this.formParam={}
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			// 确定提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.formParam.bScore=this.formParam.bScore*1;
						this.formParam.aScore=this.formParam.aScore*1;
						this.formParam.outputValue=this.formParam.outputValue*1;
						this.btnLoading = true
						if (this.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 获取详情
			async getDetail(){
				let res=await this.$get(`/platform-config/permissionaward/${this.id}`);
				if(res&&res.code==0){
					this.formParam=res.data
				}
			},
			// 新增
			async save() {
				let res = await this.$post('/platform-config/permissionaward', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.btnLoading=false;
					this.$emit('update:show',false);
					this.$parent.getList()
				}
			},
			// 修改
			async update(){
				let res=await this.$put('/platform-config/permissionaward',this.formParam);
				this.btnLoading=false;
				if(res&&res.code==0){
					this.$message.success("修改成功！");
					console.log(this.$parent)
					this.$parent.getList();
					this.$emit('update:show',false);
				}
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}
</style>
