<template>
	<div>
		<jf-layout>
			<template slot="head">
				<el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:permissionaward:save')" @click="openAdd()">新增</el-button>
				<div class="hint">
					<div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
					<div>
						<el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:permissionaward:delete')" :disabled="selectArr.length<=0"
							@click="delRow()">删除</el-button>
					</div>
				</div>
			</template>
			<el-table slot="body" border :data="list" @selection-change="getSelect">
				<el-table-column align="center" type="selection" width="50" />
				<el-table-column align="center" prop="permissionName" label="权限名称" />
				<el-table-column align="center" prop="content" label="奖扣权限">
					<template slot-scope="{row}">
						<div class="content flex">
							<div> B分：{{row.bScore>0?'±':''}}{{row.bScore}}</div>
							<div> A分：{{row.aScore>0?'±':''}}{{row.aScore}}</div>
							<div> 产值分：{{row.outputValue>0?'±':''}}{{row.outputValue}}</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column align="center" label="操作" width="160">
					<template slot-scope="{row}">
						<el-button size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:permissionaward:update')" @click="openAdd(row)">编辑</el-button>
						<el-button size="mini" icon="el-icon-delete" v-if="isAuth('platform-config:permissionaward:delete')" type="danger" @click="delRow(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit"  @change="getList('reset')"/>
		</jf-layout>
		<Add :show.sync="show" :id="id" />
	</div>
</template>

<script>
	import Add from "./add";
	export default {
		components: {
			Add
		},
		data() {
			return {
				show: false,
				tableLoading: false,
				pageParam: {
					page: 1,
					limit: 10
				},
				selectArr:[],
				id: null,
				list: [],
				total:0
			};
		},
		created() {
			this.getList()
		},
		methods: {
			//获取列表
			async getList() {
				this.tableLoading = true;
				let res = await this.$get('/platform-config/permissionaward/page', this.pageParam);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.list = res.data.list;
					this.total = res.data.total
				}
			},
			// 打开新增弹窗
			openAdd(row) {
				this.id = row ? row.id : null
				this.show = true;
			},
			// 选择
			getSelect(e) {
				this.selectArr = e
			},
			// 删除
			delRow(row) {
				let ids = []
				if (row) {
					ids = [row.id]
				} else {
					this.selectArr.map((v) => {
						ids.push(v.id)
					})
				}
				this.$confirm('确定执行删除操作?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.tableLoading = true;
					let res = await this.$del('/platform-config/permissionaward', ids);
					this.tableLoading = false;
					if (res && res.code == 0) {
						this.$message.success("删除成功！")
						this.getList()
					}
				}).catch(() => {});
			},
		}
	};
</script>
<style lang="scss" scoped>
	.content{
		>div{
			margin-right: 20px !important;
		}
	}
	.hint {
		display: flex;
		justify-content: space-between;
		background: #FFF4E3;
		border-radius: 5px;
		padding: 0 17px;
		margin-top: 15px;
	}
</style>
